/* eslint-disable react/no-did-update-set-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    ImageState,
} from 'Component/Image/Image.config';
import { ImageComponent as SourceImage } from 'SourceComponent/Image/Image.component';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Scandipwa/Component/Image/Component */
export class Image extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        srcset: PropTypes.string,
        sizes: PropTypes.string,
        onImageLoad: PropTypes.func,
        isPreventBlink: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        isPreventBlink: false,
    };

    static getDerivedStateFromProps(props, state) {
        const { src, isPreventBlink, srcset } = props;
        const { prevSrc, prevSrcSet } = state;
        // eslint-disable-next-line fp/no-let
        let newState = null;

        if (prevSrc !== src || prevSrcSet !== srcset) {
            if (!isPreventBlink || !src) {
                newState = {
                    ...Image.onImageChange(props),
                    prevSrc: src,
                    prevSrcSet: srcset,
                };
            }
        }

        return newState;
    }

    static onImageChange(props) {
        const { src } = props;

        if (!src) {
            return { imageStatus: ImageState.IMAGE_NOT_SPECIFIED };
        }

        return { imageStatus: ImageState.IMAGE_LOADING };
    }

    __construct(props = {}) {
        super.__construct(props);

        this.state = {
            imageStatus: ImageState.IMAGE_LOADING,
            prevSrc: props.src,
            prevSrcSet: props.srcset,
        };
    }

    preloadImage() {
        // preload image so blink will be less noticeable
        const { src, srcset } = this.props;

        const image = new window.Image();

        image.onload = () => {
            this.setState({
                prevSrc: src,
                prevSrcSet: srcset,
            });
        };

        image.src = src;

        if (srcset) {
            image.srcset = srcset;
        }
    }

    componentDidUpdate() {
        const { src, srcset } = this.props;
        const { prevSrc, prevSrcSet } = this.state;

        // this might happen only if "isPreventBlink" was enabled
        if (src !== prevSrc || srcset !== prevSrcSet) {
            this.preloadImage();
        }
    }

    onError() {
        const { onError } = this.props;

        if (onError) {
            onError();
        }

        this.setState({ imageStatus: ImageState.IMAGE_NOT_FOUND });
    }

    onLoad() {
        const { onImageLoad } = this.props;

        if (onImageLoad) {
            onImageLoad();
        }

        this.setState({ imageStatus: ImageState.IMAGE_LOADED });
    }

    renderImage() {
        const {
            alt,
            isPlaceholder,
            style,
            sizes,
        } = this.props;

        const {
            imageStatus,
            prevSrc,
            prevSrcSet,
        } = this.state;

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case ImageState.IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case ImageState.IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case ImageState.IMAGE_LOADED:
        case ImageState.IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  srcSet={ prevSrcSet }
                  src={ prevSrc || '' }
                  sizes={ sizes }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === ImageState.IMAGE_LOADING } }
                  style={ style }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ navigator.userAgent.includes('render') ? 'eager' : 'lazy' }
                />
            );
        default:
            return null;
        }
    }
}

export default Image;
