/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Image from 'Component/Image';
import { ProductCardComponent as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.style';
/**
 * Product card
 * @class ProductCard
 * @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    // eslint-disable-next-line no-unused-vars
    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail, onLoad } = this.props;

        return (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mods: { isPlaceholder: !id } } }
              isPlaceholder={ !id }
              isPreventBlink
              onImageLoad={ onLoad }
            />
        );
    }
}

export default ProductCardComponent;
